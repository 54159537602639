import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import CaseStudiesPostsList from "../containers/CaseStudiesPostsList"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import NewEbook from "../containers/NewEbook"
import Newsletter from "../containers/Newsletter"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const CaseStudiesPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query CaseStudies {
      prismicCaseStudies {
        data {
          seo_description {
            text
          }
          seo_short_description {
            text
          }
          seo_title {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          title {
            html
          }
          subtitle
          image {
            url
          }
        }
      }
      allPrismicCaseStudiesPost(
        sort: { fields: first_publication_date, order: DESC }
      ) {
        edges {
          node {
            uid
            id
            tags
            first_publication_date(formatString: "MMM DD, YYYY")
            data {
              publication_date(formatString: "MMM DD, YYYY")
              intro_text
              title {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    title,
    image,
    subtitle,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = data.prismicCaseStudies.data

  const { edges } = data.allPrismicCaseStudiesPost

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/case-studies">
        <ContentWrapper>
          <Banner title={title.html} subtitle={subtitle} image={image.url} />
          <Newsletter pathname="/case-studies" />
          <Breadcrumbs page="Case Studies" />
          <NewEbook />
          <CaseStudiesPostsList content={edges} />
          <Consultation pathname="/case-studies" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default CaseStudiesPage
